










import { defineComponent, computed, onMounted } from '@vue/composition-api'
import VerticalTabs from '@/components/VerticalTabs.vue'
import Loading from '@/elements/Loading.vue'

import { OrderTypesEnum } from '@/lib/support/models/GeneratedTypes/xOrders/xOrdersGeneral'
import store from '@/store'
import { UpwardOpportunityTypes } from '@/lib/common/upwardOpportunityTypes'

export default defineComponent({
  name: 'OrdersIndex',
  components: {
    VerticalTabs,
    Loading,
  },
  setup(props, ctx) {
    const id = computed(() => ctx.root.$route.params.id)
    const routeId = computed(() => (id.value ? `${id.value}/` : ''))

    /**
     * uses route to determine that we are in a camp or clinic.
     * @private
     */
    const isClinic = computed(() => ctx.root.$route.fullPath.indexOf('/clinic/') >= 0)

    const isCamp = computed(() => ctx.root.$route.fullPath.indexOf('/camp/') >= 0)

    const isConnect = computed(
      () => store.getters.leagueAbstraction.programType == UpwardOpportunityTypes.CONNECT
    )

    const isupwardSelect = computed(() => store.getters.leagueAbstraction.isUpwardSelect)

    const baseRoute = computed(() => {
      if (isClinic.value) {
        return `/programs/clinic/${routeId.value}ordering/`
      }
      if (isCamp.value) {
        return `/programs/camp/${routeId.value}ordering/`
      }
      if (isConnect.value) {
        return `/programs/connect/${routeId.value}ordering/`
      }
      if (isDigital.value) {
        return `/programs/digitalleague/${routeId.value}ordering/`
      }
      return `/programs/league/${routeId.value}ordering/`
    })

    const tabs = computed(() => {
      if (isClinic.value) {
        return [
          { text: 'Shipping Timelines', to: `${baseRoute.value}shippingtimelines` },
          { text: 'Order History', to: `${baseRoute.value}history` },
          { text: 'Merchandise', to: `${baseRoute.value}products/${OrderTypesEnum.gameday}/select` },
          { text: 'Returns', to: `${baseRoute.value}returns` },
        ]
      }
      if (isConnect.value) {
        return [
          { text: 'Shipping Timelines', to: `${baseRoute.value}shippingtimelines` },
          { text: 'Order History', to: `${baseRoute.value}history` },
          { text: 'Merchandise', to: `${baseRoute.value}products/${OrderTypesEnum.gameday}/select` },
        ]
      }
      if (isDigital.value) {
        return [
          { text: 'Order History', to: `${baseRoute.value}history` },
          { text: 'Startup', to: `${baseRoute.value}products/${OrderTypesEnum.startup}/select` },
          { text: 'Merchandise', to: `${baseRoute.value}products/${OrderTypesEnum.gameday}/select` },
        ]
      }
      if (isupwardSelect.value) {
        return [
          { text: 'Shipping Timelines', to: `${baseRoute.value}shippingtimelines` },
          { text: 'Order History', to: `${baseRoute.value}history` },
          { text: 'Merchandise', to: `${baseRoute.value}products/${OrderTypesEnum.gameday}/select` },
          { text: 'League', to: `${baseRoute.value}league` },
          { text: 'Returns', to: `${baseRoute.value}returns` },
        ]
      }
      return [
        { text: 'Shipping Timelines', to: `${baseRoute.value}shippingtimelines` },
        { text: 'Order History', to: `${baseRoute.value}history` },
        { text: 'Startup', to: `${baseRoute.value}products/${OrderTypesEnum.startup}/select` },
        { text: 'Merchandise', to: `${baseRoute.value}products/${OrderTypesEnum.gameday}/select` },
        { text: isCamp.value ? 'Camp' : 'League', to: `${baseRoute.value}league` },
        { text: 'Returns', to: `${baseRoute.value}returns` },
      ].filter((x) => x.text)
    })

    const isDigital = computed(() => ctx.root.$route.path.indexOf('digitalleague') > 0)

    onMounted(() => {
      // If there is no program, then this route is invalid
      if (!routeId.value) {
        ctx.root.$router.push('/programs/new/')
      }
    })

    return {
      tabs,
    }
  },
})
